import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { STORAGE_APP } from './constants';
import { PreferencesService } from '../services/preferences.service';

export interface App {
  firstAccess: Date;
  // TODO temp hack, because the backend api does not deal with rate api limit for now
  lastConfirmationEmailRequest: Date | null;
}

@Injectable({ providedIn: 'root' })
export class AppState {
  constructor(private preferenceService: PreferencesService) {}

  appReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  app$!: BehaviorSubject<App>;
  stateReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly STORAGE_KEY = STORAGE_APP;

  private initialApp: App = {
    firstAccess: new Date(),
    lastConfirmationEmailRequest: null,
  };

  async initialize(): Promise<void> {
    const storedApp = await this.get();

    await this.set(storedApp || this.initialApp);

    return Promise.resolve();
  }

  private async get(): Promise<App | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(settings: Partial<App>): Promise<void> {
    const updatedOptions: App = {
      ...this.app$?.value,
      ...settings,
    };

    if (this.app$) {
      this.app$.next(updatedOptions);
    } else {
      this.app$ = new BehaviorSubject<App>(updatedOptions);
    }

    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(updatedOptions));
  }
}
